.card{
    margin-right: 50px;
    margin-left: 80px;
}
.bottomCard{
    bottom: 0px;
    text-align: left;
    font-size: 18px;
    position: absolute;
}
.categoryButton{
    border-radius: 30px;
}
.linkLayer{
    text-decoration: none;
}
.cardlayer{
    margin-bottom: 10px;
}

.LayerSection{
    margin-top: 100px
}

.returnButton{
    text-align: center;
}

.fleetText{
    min-height: 120px;
}
.fleetImage{
    display: flex;
    align-items: center;
    justify-content: center;
}
.fleetDescription{
    position: relative;
}
