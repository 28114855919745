.map-wrap {
  position: relative;
  width: 100%;
  height: calc(100vh - 77px); /* calculate height of the screen minus the heading */
  }
  
.map {
  position: absolute;
  width: 100%;
  height: 100%;
}

.maplibregl-ctrl-top-left .maplibregl-ctrl {
  float: left;
  margin: 15px 0 0 60px;
}

.maplibregl-ctrl-top-right {
  right: 0;
  top: 5px;
}

.maplibregl-ctrl-top-left {
  left: 5px;
  top: 0;
}

/* Search box Geocodor */

/* Basics */
.maplibregl-ctrl-geocoder,
.maplibregl-ctrl-geocoder *,
.maplibregl-ctrl-geocoder *:after,
.maplibregl-ctrl-geocoder *:before {
  box-sizing: border-box;
}

.maplibregl-ctrl-geocoder {
  font-size: 18px;
  line-height: 24px;
  font-family: "Open Sans", "Helvetica Neue", Arial, Helvetica, sans-serif;
  position: left;
  background-color: #fff;
  width: 100%;
  min-width: 300px;
  z-index: 1;
  border-radius: 4px;
  transition: width 0.25s, min-width 0.25s;
}

.maplibregl-ctrl-geocoder--input {
  font-family: "Montserrat";
  width: 100%;
  border: 0;
  background-color: transparent;
  margin: 0;
  height: 50px;
  color: #404040; /* fallback */
  color: rgba(0, 0, 0, 0.75);
  padding: 6px 45px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.maplibregl-ctrl-geocoder--input::-ms-clear {
  display: none; /* hide input clear button in IE */
}

.maplibregl-ctrl-geocoder--input:focus {
  color: #404040; /* fallback */
  color: rgba(0, 0, 0, 0.75);
  outline: 0;
  box-shadow: none;
  outline: thin dotted;
}

.maplibregl-ctrl-geocoder .maplibregl-ctrl-geocoder--pin-right > * {
  z-index: 2;
  position: absolute;
  right: 8px;
  top: 7px;
  display: none;
}

.maplibregl-ctrl-geocoder,
.maplibregl-ctrl-geocoder .suggestions {
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1);
}

/* Collapsed */
.maplibregl-ctrl-geocoder.maplibregl-ctrl-geocoder--collapsed {
  width: 50px;
  min-width: 50px;
  transition: width 0.25s, min-width 0.25s;
}

/* Suggestions */
.maplibregl-ctrl-geocoder .suggestions {
  background-color: #fff;
  border-radius: 4px;
  left: 0;
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  width: 100%;
  top: 110%; /* fallback */
  top: calc(100% + 6px);
  z-index: 1000;
  overflow: hidden;
  font-size: 15px;
}

.maplibregl-ctrl-bottom-left .suggestions,
.maplibregl-ctrl-bottom-right .suggestions {
  top: auto;
  bottom: 100%;
}

.maplibregl-ctrl-geocoder .suggestions > li > a {
  cursor: default;
  display: block;
  padding: 6px 12px;
  color: #404040;
}

.maplibregl-ctrl-geocoder .suggestions > .active > a,
.maplibregl-ctrl-geocoder .suggestions > li > a:hover {
  color: #404040;
  background-color: #f3f3f3;
  text-decoration: none;
  cursor: pointer;
}

.maplibregl-ctrl-geocoder--suggestion {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.maplibre-ctrl-geocoder--suggestion-icon {
  min-width: 30px;
  min-height: 24px;
  max-width: 30px;
  max-height: 24px;
  padding-right: 12px;
}

.maplibregl-ctrl-geocoder--suggestion-info {
  display: flex;
  flex-direction: column;
}

.maplibregl-ctrl-geocoder--suggestion-match {
  font-weight: bold;
}

.maplibregl-ctrl-geocoder--suggestion-title,
.maplibregl-ctrl-geocoder--suggestion-address {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.maplibregl-ctrl-geocoder--result {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.maplibre-ctrl-geocoder--result-icon {
  min-width: 30px;
  min-height: 24px;
  max-width: 30px;
  max-height: 24px;
  padding-right: 12px;
}

.maplibregl-ctrl-geocoder--result-title {
  font-weight: bold;
}

.maplibregl-ctrl-geocoder--result-title,
.maplibregl-ctrl-geocoder--result-address {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

/* Icons */
.maplibregl-ctrl-geocoder--icon {
  display: inline-block;
  vertical-align: middle;
  speak: none;
  fill: #757575;
  top: 15px;
}

.maplibregl-ctrl-geocoder--icon-search {
  position: absolute;
  top: 13px;
  left: 12px;
  width: 23px;
  height: 23px;
}

.maplibregl-ctrl-geocoder--button {
  padding: 0;
  margin: 0;
  border: none;
  cursor: pointer;
  background: #fff;
  line-height: 1;
}

.maplibregl-ctrl-geocoder--icon-close {
  width: 20px;
  height: 20px;
  margin-top: 8px;
  margin-right: 3px;
}

.maplibregl-ctrl-geocoder--button:hover .maplibregl-ctrl-geocoder--icon-close {
  fill: #909090;
}

.maplibregl-ctrl-geocoder--icon-loading {
  width: 26px;
  height: 26px;
  margin-top: 5px;
  margin-right: 0px;
  -moz-animation: rotate 0.8s infinite cubic-bezier(0.45, 0.05, 0.55, 0.95);
  -webkit-animation: rotate 0.8s infinite cubic-bezier(0.45, 0.05, 0.55, 0.95);
  animation: rotate 0.8s infinite cubic-bezier(0.45, 0.05, 0.55, 0.95);
}

/* Animation */
@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* Media queries*/
@media screen and (min-width: 640px) {
  .maplibregl-ctrl-geocoder.maplibregl-ctrl-geocoder--collapsed {
    width: 36px;
    min-width: 36px;
  }

  .maplibregl-ctrl-geocoder {
    font-size: 15px;
    line-height: 20px;
    max-width: 360px;
  }
  .maplibregl-ctrl-geocoder .suggestions {
    font-size: 13px;
  }

  .maplibregl-ctrl-geocoder--icon {
    top: 8px;
  }

  .maplibregl-ctrl-geocoder--icon-close {
    width: 16px;
    height: 16px;
    margin-top: 3px;
    margin-right: 0;
  }

  .maplibregl-ctrl-geocoder--icon-search {
    left: 7px;
    width: 20px;
    height: 20px;
  }

  .maplibregl-ctrl-geocoder--input {
    height: 36px;
    padding: 6px 35px;
  }

  .maplibregl-ctrl-geocoder--icon-loading {
    width: 26px;
    height: 26px;
    margin-top: -2px;
    margin-right: -5px;
  }

  .maplibre-gl-geocoder--error {
    color: #909090;
    padding: 6px 12px;
    font-size: 16px;
    text-align: center;
  }
}


.maplibregl-ctrl-bottom-left .maplibregl-ctrl {
  margin: 0 0 10px 65px;
}
.maplibregl-ctrl-scale {
  border: 1px solid #333;
}

/* Tile Overlay style*/
.map-overlay {
  font-size: 12px;
  font-family: "Montserrat";
  position: absolute;
  width: 17%;
  top: 50px;
  left: 54px;
  padding: 10px;
}

.map-overlay .map-overlay-inner {
  background-color: #ffffff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
}

.map-overlay input {
  margin: 2px;
}
.selectedLayer {
  color: #0075A9;
  font-size: 14px;
  font-family: "Montserrat";
}

/* Button style inside popup */
.maplibregl-popup-content button {
  font-size: 12px;
  font-family: "Montserrat";
  padding: 0;
  border: none;
  background: none;
}

/* Refresh button in the map */
.refreshDiv{
  position: fixed;
  top: 195px;
  right: 0px;
  float: right;
  margin: 10px 10px 0 0;
}

.refreshButton{
  border-radius: 4px;
  background: #ffffff;
  color: black;
  border-width: 0!important;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, .1);
  padding: 4px;
  width: 29px;
  height: 29px;

}

.refreshButton:hover{
  background: #e7e7e7;
}

.refreshingMessage{
  color: #0075A9;
  font-weight: bold;
  font-size: 14px;
  font-family: "Montserrat";
  position: fixed;
  bottom: 0px;
  right: 45%;
}

.form-switch {
  display: flex;
  flex-direction: row;
  padding: 0;
  margin: 0;
}

.form-switch label {
 margin-right: 50px !important;
}

.success-switch:checked {
  background-color: #82e600; /* Bootstrap success color */
  border-color: #82e600; /* Ensure the border matches the background */
}
.success-switch:checked::before {
  background-color: white; /* Color of the toggle circle */
}

.aFleet{
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-weight: 500;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
  padding: 12px 16px;
  color: #ffffff;
  font-size: 13px;
  position: relative;
  align-items: center;
}
