body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.error-section.container-404 {
  height: calc(100vh - 66px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.error-section .text-404 {
  font-size: 120px;
  margin-bottom: 20px;
  font-family: 'Abril Fatface', cursive;
  color: #ff0000;
}

.error-section .text-not-found {
  font-size: 18px;
  margin-bottom: 30px;
  line-height: 1.6;
  color: #585959;
}

.error-section .icon-404 {
  margin: 0 auto;
  position: relative;
  width: 250px;
  height: 250px;
  margin-top: -40px;
}

.error-section .ghost {
  width: 50%;
  height: 53%;
  left: 25%;
  top: 10%;
  position: absolute;
  border-radius: 50% 50% 0 0;
  background: #EDEDED;
  border: 1px solid #BFC0C0;
  border-bottom: none;
  animation: float 2s ease-out infinite;
}

.error-section .ghost-copy {
  width: 50%;
  height: 53%;
  left: 25%;
  top: 10%;
  position: absolute;
  border-radius: 50% 50% 0 0;
  background: #EDEDED;
  border: 1px solid #BFC0C0;
  border-bottom: none;
  animation: float 2s ease-out infinite;
  z-index: 0;
}

.error-section .face {
  position: absolute;
  width: 100%;
  height: 60%;
  top: 20%;
}

.error-section .eye,
.error-section .eye-right {
  position: absolute;
  background: #585959;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  top: 40%;
}

.error-section .eye {
  left: 25%;
}

.error-section .eye-right {
  right: 25%;
}

.error-section .mouth {
  position: absolute;
  top: 50%;
  left: 45%;
  width: 10px;
  height: 10px;
  border: 3px solid;
  border-radius: 50%;
  border-color: transparent #585959 #585959 transparent;
  transform: rotate(45deg);
}

.error-section .one,
.error-section .two,
.error-section .three,
.error-section .four {
  position: absolute;
  background: #EDEDED;
  top: 85%;
  width: 25%;
  height: 23%;
  border: 1px solid #BFC0C0;
  z-index: 0;
}

.error-section .one {
  border-radius: 0 0 100% 30%;
  left: -1px;
}

.error-section .two {
  left: 23%;
  border-radius: 0 0 50% 50%;
}

.error-section .three {
  left: 50%;
  border-radius: 0 0 50% 50%;
}

.error-section .four {
  left: 74.5%;
  border-radius: 0 0 30% 100%;
}

.error-section .shadow {
  position: absolute;
  width: 30%;
  height: 7%;
  background: #BFC0C0;
  left: 35%;
  top: 80%;
  border-radius: 50%;
  animation: scale 2s infinite;
}

@keyframes scale {
  0% {
      transform: scale(1);
  }

  50% {
      transform: scale(1.1);
  }

  100% {
      transform: scale(1);
  }
}

@keyframes float {
  50% {
      transform: translateY(15px);
  }
}

